import request from '_utils/request'

const apply_api_product = (productId) => {
  return request({
    url: '/api/product/applyImmediatelyApi',
    method: 'POST',
    data: {
      productId
    }
  })
}

const get_api_product = () => {
  return request({
    url: '/api/product/getXdApiProduct',
    method: 'GET'
  })
}

export {
  apply_api_product,
  get_api_product
}
