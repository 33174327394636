import request from '_utils/request'
import { encode } from 'js-base64'
import getSearch from '_utils/getSearch'

// 通过用户提交资料匹配产品
const match_product_by_post_user_message = (data) => {
  // 特殊渠道请求接口不同
  const url = process.env.VUE_APP_SAFE_VALIDATE ? 'api/userInfo/userInfoWrite/two-sj' : '/api/userInfo/userInfoWrite/two'

  // 特殊渠道进行处理，姓名和身份证号转base64
  if (process.env.VUE_APP_SAFE_VALIDATE) {
    data.idCard = encode(data.idCard)
    data.userName = encode(data.userName)
  }

  data.isH5 = 1
  return request({
    url,
    method: 'POST',
    data
  })
}

// 注册页通过用户提交资料匹配产品
const register_match_product_by_post_user_message = (data) => {
  data.isH5 = 1
  return request({
    url: '/api/userInfo/userInfoWrite/nonIdCard ',
    method: 'POST',
    data
  })
}

// 申请大额贷
const apply_big_loan_product = ({ productId, instApiState, bigLoanType }) => {
  return request({
    url: `/api/bigLoan/applyImmediately/${productId}`,
    method: 'GET',
    params: {
      bigLoanType,
      instApiState,
      instSource: 2,
    }
  })
}

// 在申请大额度前传输个人信息授权书截图
const save_personal_protocol_image = ({ data, name, companyName, address, productName, productCompanyName }) => {
  const id = localStorage.getItem('agreementId')
  const channelSign = getSearch().channelSign
  return request({
    url: `/api/user/saveAgreement/${id}?institutionName=${name}&companyName=${companyName}&address=${address}&channelSign=${channelSign}&productName=${productName}&productCompanyName=${productCompanyName}`,
    method: 'POST',
    data
  })
}

// 申请贷超列表中的产品
const to_apply_super_loan_list_product = (id) => {
  return request({
    url: '/api/product/applyImmediately/' + id,
    method: 'GET',
    params: {
      isH5: 1
    }
  })
}

export {
  match_product_by_post_user_message,
  register_match_product_by_post_user_message,
  apply_big_loan_product,
  save_personal_protocol_image,
  to_apply_super_loan_list_product
}
