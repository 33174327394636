const content = `
<div><p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt; text-align:center"><span
  style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">个人信息授权书</span>
</p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">【重要提示】尊敬的客户，为保护您的合法权益，并获得您的同意授权使用您的个人信息,</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">&#xa0;</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">请您完整地阅读本授权书，特别是免除或减轻我们及我们的关联方、合作机构的条款。</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">当您勾选同意《个人信息授权书》时，即表示您已同意我们按照本授权书来合法收集、使用和保护您的个人信息，本授权书即时生效。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">定义</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">1、授权人：{name}</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">2、身份证号：{idCard}</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">3、授权时间：{time}</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">4、被授权人：</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">“</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">51微钱包</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">”</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">平台</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">5、贷款服务机构：{institutionName}，公司名称：{companyName}，公司地址：{address}；指为您提供贷款产品或贷款产品相关信息服务的机构及合作机构（包括但不限于相关人信息采集及使用授权给金融公司、信托公司、小额贷款公司或网络借贷公司等）。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">本人已清楚并知悉被授权人的合作机构通过被授权人平台（“51微钱包”APP、H5页面，以下简称“平台”）为本人提供借款信息服务，现本人自愿主动申请在平台使用上述借款服务。本人清楚理解如下授权内容的含义并同意授权内容如下：</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">授权内容</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">一、为申请【贷款产品】，本人授权被授权人对本人的以下信息进行收集，并将相关信息提供给贷款机构。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">1、必须提供：此项业务功能（贷款产品申请和管理）需要依赖部分信息才得以运行。本人选择使用该项业务功能，同意向被授权人提供的必要信息包括：</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-decoration:underline; text-transform:none">手机号码；</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">2、可能需要提供：根据不同产品的页面要求，</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">本人同意向被授权人提供本人的</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-decoration:underline; text-transform:none">姓名、证件信息、身份证号码</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">；本人知悉，如我选择不提供或不同意采集、使用以上个人信息，被授权人将无法提供相应服务</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">3、自主填写：</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">&#xa0;</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">本人自主选择向被授权人提供或允许被授权人收集下列信息：</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-decoration:underline; text-transform:none">申请金额、所在城市、芝麻分信息、社保信息、公积金信息、房产信息、车产信息、保险信息、学历信息、营业执照信息、逾期信息、信用卡信息、职业信息、花呗额度信息、京东白条额度信息等</span><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">二、授权期限</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">自本人作出本授权承诺之日起，至本人在被授权人与提供产品服务的机构处的业务终结之日。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">本人承诺并保证，本人在本授权书项下所提供的所有信息真实、准确、完整、有效，且不会侵犯任何第三方的合法权利。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">三、联系热线</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">若您希望联系为您服务的贷款机构/银行，可在申请成功页面，点击右上角客服电话进行咨询和投诉。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">四、争议解决</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">若本人与被授权人发生任何纠纷或争议，应友好协商。协商不成的，本人同意将纠纷提交至“51微钱包”平台所属公司当地法院。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; font-weight:bold; text-transform:none">本人已知悉《个人信息授权书》所有内容（特别是加粗字体内容）的意义及由此产生的法律效力，自愿作出上述授权，本授权书是本人真实的意思表示，本人同意承担由此带来的一切法律后果。</span>
  </p>
  <p style="background-color:#ffffff; line-height:9.5pt; margin:6pt 0pt"><span
    style="background-color:#ffffff; color:#606266; font-family:Helvetica; font-size:9.5pt; font-style:normal; text-transform:none">（为保护您的个人信息，本授权书在向您展示时将部分隐藏您的身份信息，您的身份信息详情以您在本平台账户对应的实名信息为准）</span>
  </p>
  <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
  </p></div>
`

export default content
